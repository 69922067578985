import React from 'react'
import Style from './button.less'
// import { withRouter } from 'react-router-dom';
import { Button, Toast } from 'antd-mobile';
class ButtonStyle extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={Style.vote_immit}>
                <Button onClick={this.props.clickType} className={Style.button_style} >{this.props.title}</Button>
            </div>
        )
    }
}
export default ButtonStyle;