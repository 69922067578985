import { INIT_HAVE_TIME,DRIVE_SIGN_UP } from '../actionTypes'
//检测是否报名,活动是否开始
export function inithavetimereducer(state = null,action){
    switch(action.type){
        case INIT_HAVE_TIME:
        return action.data
        default:
        return state
    }
}
//报名接口
export function drivesignupreducer(state = null,action){
    switch(action.type){
        case DRIVE_SIGN_UP:
        return action.data
        default:
        return state
    }
}
