import React from 'react'
import Style from './turntable.less'
import axios from 'axios'
import '../../common/axios'
import { Carousel, Toast } from 'antd-mobile';
import BigImg from '../../static/images/truntable/bcg.jpg'
import TitleImg from '../../static/images/truntable/title.png'
import People from '../../static/images/truntable/people.png'
import Round from '../../static/images/truntable/Round.png'
import Mountain from '../../static/images/truntable/mountain.png'
import Rule from '../../static/images/truntable/rule.png'
import TrunImg from '../../static/images/truntable/trun_img.png'
import { connect } from 'react-redux'
import { inittableMethod, drawlistMethod } from '../../store/actions/truntable_action'
var img0 = new Image();
var img1 = new Image();
var img2 = new Image();
var img3 = new Image();
var img4 = new Image();
var img5 = new Image();
var arr = [];
var arrindex = [];
var lastdistance = 0;
@connect(
    state => ({
        initturntablelist: state.initturntablelist,
        drawlistReducer: state.drawlistReducer
    }),
    dispatch => ({
        inittableMethod: (data) => dispatch(inittableMethod(data)),
        drawlistMethod: (data) => dispatch(drawlistMethod(data)),
    })
)
class TurnTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            awards: [],//大转盘的奖品列表
            animation: true,
            fileRootPath: "",//"http://co.dev.touty.io"
            startRadian: -90 * Math.PI / 180,//大转盘的开始弧度(canvas绘制圆从水平方向开始，所以这里调整为垂直方向) 弧度计算公式：角度*Math.PI/180
            canBeClick: true,//判断抽奖有没有结束
            canvas: '',
            content: '',
            actId: 13,
            giftItem: {},
        }
    }

    componentDidMount() {
        axios({
            url: "/ctds/gifts/wx/getGift",
            method: 'GET',
            params: {
                actId: this.state.actId
            }
        }).then(res => {
            if (res.data.ret == 0) {
                res.data.retData.forEach((ele, index) => {
                    if (index % 2 == 0) {
                        ele.color = "#FFC200";
                    } else {
                        ele.color = "#FFE122";
                    }
                })
                this.setState({
                    awards: res.data.retData
                })
                this.onLoadPage(res.data.retData);
                this.props.drawlistMethod();
            }
        }).catch(err => {
            console.log(err)
        })
    }
    onLoadPage(awards) {
        let { startRadian } = this.state;
        let canvas = document.getElementById("wheelcanvas");
        // 获取canvas的上下文,context含有各种api用来操作canvas
        let context = canvas.getContext('2d');
        this.setState({ canvas: canvas, context: context });
        context.save();
        // 新建一个路径,画笔的位置回到默认的坐标(0,0)的位置
        // 保证了当前的绘制不会影响到之前的绘制
        context.beginPath();
        // 设置填充转盘用的颜色,fill是填充而不是绘制
        context.fillStyle = '#fff';
        // 绘制一个圆,有六个参数,分别表示:圆心的x坐标,圆心的y坐标,圆的半径,开始绘制的角度,结束的角度,绘制方向(false表示顺时针)
        context.arc(211, 211, 211, startRadian, Math.PI * 2 + startRadian, false);
        // 将设置的颜色填充到圆中,这里不用closePath是因为closePath对fill无效.
        context.fill();

        // 将画布的状态恢复到上一次save()时的状态
        context.restore();
        // 第一个奖品色块开始绘制时开始的弧度及结束的弧度
        let RadianGap = Math.PI * 2 / awards.length, endRadian = startRadian + RadianGap;
        for (let i = 0; i < awards.length; i++) {
            context.save();
            context.beginPath();
            // 为了区分不同的色块,使用随机生成的颜色作为色块的填充色
            context.fillStyle = awards[i].color;
            // 这里需要使用moveTo方法将初始位置定位在圆点处,这样绘制的圆弧都会以圆点作为闭合点
            context.moveTo(211, 211);
            // 画圆弧时,每次都会自动调用moveTo,将画笔移动到圆弧的起点,半径设置的比转盘稍小一点
            context.arc(211, 211, 211, startRadian, endRadian, false);
            context.fill();
            context.restore();
            // 开始绘制文字
            context.save();
            //设置文字颜色
            context.fillStyle = '#f00';
            //设置文字样式
            context.font = "18px Arial";
            // 改变canvas原点的位置,简单来说,translate到哪个坐标点,那么那个坐标点就将变为坐标(0, 0)
            context.translate(
                211 + Math.cos(startRadian + RadianGap / 2) * 211,
                211 + Math.sin(startRadian + RadianGap / 2) * 211
            );
            // 旋转角度,这个旋转是相对于原点进行旋转的.
            context.rotate(startRadian + RadianGap / 2 + Math.PI / 2);
            // if( i == 0){
            //     img0.src = awards[0].image;
            //     context.drawImage(img0, -30, 60, 60, 60);
            // }else if( i == 1){
            //     img1.src = awards[1].image;
            //     context.drawImage(img1, -30, 60, 60, 60);
            // }else if( i == 2){
            //     img2.src = awards[2].image;
            //     context.drawImage(img2, -30, 60, 60, 60);
            // }else if( i == 3){
            //     img3.src = awards[3].image;
            //     context.drawImage(img3, -30, 60, 60, 60);
            // }else if( i == 4){
            //     img4.src = awards[4].image;
            //     context.drawImage(img4, -30, 60, 60, 60);
            // }else if( i == 5){
            //     img5.src = awards[5].image;
            //     context.drawImage(img5, -30, 60, 60, 60);
           // }
            //this.getImageList(context, awards[i].image, i);
            // 这里就是根据获取的各行的文字进行绘制,maxLineWidth取70,相当与一行最多展示5个文字
            this.getLineTextList(context, awards[i].giftName, 120).forEach((line, index) => {
                // 绘制文字的方法,三个参数分别带:要绘制的文字,开始绘制的x坐标,开始绘制的y坐标
                context.fillText(line, -context.measureText(line).width / 2, ++index * 55)

            });
            context.restore();
            // 每个奖品色块绘制完后,下个奖品的弧度会递增
            startRadian += RadianGap;
            endRadian += RadianGap;
        }
        //下面是画中间的小圆
        context.save();
        // 新建一个路径,画笔的位置回到默认的坐标(0,0)的位置
        // 保证了当前的绘制不会影响到之前的绘制
        context.beginPath();
        // 设置填充转盘用的颜色,fill是填充而不是绘制
        context.fillStyle = '#fff';
        // 绘制一个圆,有六个参数,分别表示:圆心的x坐标,圆心的y坐标,圆的半径,开始绘制的角度,结束的角度,绘制方向(false表示顺时针)
        context.arc(211, 211, 70, startRadian, Math.PI * 2 + startRadian, false);
        // 将设置的颜色填充到圆中,这里不用closePath是因为closePath对fill无效.
        context.fill();
        // 将画布的状态恢复到上一次save()时的状态
        context.restore();
    }
    //绘制图片
    getImageList(context, imgSrc, index) {
        arr.push(new Image('image' + index, index))
        arr[index].src = imgSrc;

        // for (var j = 0; j <= arr.length; j++) {
        //     if (index == j) {
        //         context.drawImage(arr[index], -28, 60, 60, 60);
        //         // context.drawImage(arr[index], -35, 50, 60, 60);
        //     }
        // }
    }
    //绘制文字，文字过长进行换行，防止文字溢出
    getLineTextList(context, text, maxLineWidth) {
        let wordList = text.split(''), tempLine = '', lineList = [];
        for (let i = 0; i < wordList.length; i++) {
            if (context.measureText(tempLine).width >= maxLineWidth) {
                lineList.push(tempLine);
                maxLineWidth -= context.measureText(text[0]).width;
                tempLine = ''
            }
            tempLine += wordList[i]
        }
        lineList.push(tempLine);
        return lineList
    }

    // 这个方法是为了将canvas再window中的坐标点转化为canvas中的坐标点
    windowToCanvas(canvas, e) {
        // getBoundingClientRect这个方法返回html元素的大小及其相对于视口的位置
        const canvasPostion = canvas.getBoundingClientRect(), x = e.clientX, y = e.clientY;
        return {
            x: x - canvasPostion.left,
            y: y - canvasPostion.top
        }
    };

    //点击抽奖让转盘转起来
    draw(e) {
        if (this.state.canBeClick) {
            this.state.canBeClick = false;
            axios({
                url: "/ctds/giftRecords/wx/doRotaryTableGift",
                method: 'GET',
                params: {
                    actId: this.state.actId,
                    wxToken: localStorage.getItem('Token'),
                    // wxToken : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzZXNzaW9uS2V5IjoiQm11cFBtQ0hRb24xOEUxVGg4aFFNZz09IiwicmVsZWFzZVRpbWVNaWxsaXMiOiIxNTkxMTQ5MjU4ODE1Iiwib3BlbklkIjoib1g3NzI1ZGszTkVIMUM2RXZEaWlMNks2QUF3YyIsInVzZXJKc29uIjoie1wiQHR5cGVcIjpcImNvbS56dy5hZG1pbi5zZXJ2ZXIubW9kZWwuTWluaVVzZXJcIixcImFkZHJlc3NcIjpcIuecgeWkllwiLFwiY29uc3VsdGFudElkXCI6MjIxLFwiY3JlYXRlVGltZVwiOjE1ODgxNDIzNDUwMDAsXCJpZFwiOjEsXCJvcGVuSWRcIjpcIm9YNzcyNWRrM05FSDFDNkV2RGlpTDZLNkFBd2NcIixcInBob25lXCI6XCIxODEzNjQ2NDAzN1wiLFwicGhvbmVUaW1lXCI6MTU5MDgwNzA0NzAwMCxcInBvaW50c1wiOjAsXCJ1cGRhdGVUaW1lXCI6MTU5MDgwNzA0NjAwMH0iLCJleHAiOjE1OTM3NDEyNTh9.9hMgBf_Ot9Um6-2kYzUY95Yva7o6rUbQl9WJMmF4qy8',
                }
            }).then(res => {
                if (res.data.ret == 0) {
                    this.setState({
                        giftItem: res.data.retData
                    })
                    this.state.awards.forEach((item, index) => {
                        if (item.id == res.data.retData.giftId) {
                            arrindex.push(index)
                        }
                    })
                    const { canvas, context } = this.state;
                    // 只要抽奖没有结束，就不让再次抽奖
                    // if (!this.state.canBeClick) return;
                    // this.state.canBeClick = false;
                    // 每次点击抽奖，都将初始化角度重置
                    this.state.startRadian = 0;
                    const distance = this.distanceToStop();
                    this.rotatePanel(distance);//调用处理旋转的方法
                }else{
                    this.state.canBeClick = true;
                }

            }).catch(err => {
                console.log(err)

            })
        }



    }

    // 处理旋转的关键方法
    rotatePanel(distance) {
        // 这里用一个很简单的缓动函数来计算每次绘制需要改变的角度，这样可以达到一个转盘从块到慢的渐变的过程
        let changeRadian = (distance - this.state.startRadian) / 20;
        this.state.startRadian += changeRadian;
        // 当最后的目标距离与startRadian之间的差距低于0.0001时，就默认奖品抽完了，可以继续抽下一个了。
        if (distance - this.state.startRadian <= 0.001) {
            this.state.canBeClick = false;
            setTimeout(() => {
                this.props.history.push({
                    pathname: '/turn_result',
                    state: {
                        id: this.state.giftItem.id
                    }
                })
            }, 1000)
            return;
        };
        // 初始角度改变后，需要重新绘制
        this.onLoadPage(this.state.awards);
        // 循环调用rotatePanel函数，使得转盘的绘制连续，造成旋转的视觉效果
        window.requestAnimationFrame(this.rotatePanel.bind(this, distance));
    }

    distanceToStop() {
        // middleDegrees为奖品块的中间角度（最终停留都是以中间角度进行计算的）距离初始的startRadian的距离，distance就是当前奖品跑到指针位置要转动的距离。
        let middleDegrees = 0, distance = 0;
        // 映射出每个奖品的middleDegrees
        let awardsToDegreesList = this.state.awards.map((data, index) => {
            let awardRadian = (Math.PI * 2) / this.state.awards.length;
            return awardRadian * index + (awardRadian * (index + 1) - awardRadian * index) / 2
        });
        // 随机生成一个索引值，来表示此次抽奖应该中的奖品
        // console.log(arrindex)

        const currentPrizeIndex = arrindex[0];
        //当前奖品应该中的奖品是：
        console.log(this.state.awards[currentPrizeIndex]);
        middleDegrees = awardsToDegreesList[currentPrizeIndex];
        // 因为指针是垂直向上的，相当坐标系的Math.PI/2,所以这里要进行判断来移动角度
        distance = Math.PI * 3 / 2 - middleDegrees;
        distance = distance > 0 ? distance : Math.PI * 2 + distance;
        // 这里额外加上后面的值，是为了让转盘多转动几圈，看上去更像是在抽奖
        return distance + Math.PI * 10;
    }

    render() {
        return <div className={Style.wheelContainer} style={{ backgroundImage: `url(${BigImg})` }}>
            <div className={Style.top}>
                <img src={TitleImg} alt="" className={Style.titleimg} />
            </div>
            <div className={Style.wheelMain}>
                <div className={Style.wheel}>
                    <img className={Style.wheelCircle} src="https://wp.touty.io/api/file/5d0c51ff2ab79c000897ecac.image" />
                    <canvas className={Style.item} id="wheelcanvas" height={422} width={422} />
                    <img onClick={this.draw.bind(this)} className={Style.pointer} src="https://wp.touty.io/api/file/5d0c51c02ab79c000897ecaa.image" />
                </div>
                <img alt='' src={Mountain} className={Style.wheelButton_mount} />
                <img alt='' src={People} className={Style.wheelButton_people} />
            </div>
            <div className={Style.wheelButton}>
                <img src={Rule} alt="" className={Style.rule} />
                {
                    this.props.drawlistReducer.length == 0 ? null : <div className={Style.names_turn} style={{ backgroundImage: `url(${TrunImg})` }}>
                        <Carousel className="my-carousel"
                            vertical
                            dots={false}
                            dragging={false}
                            swiping={false}
                            autoplay
                            infinite
                            speed={1000}
                            autoplayInterval={1000}
                            resetAutoplay={false}
                        >
                            {this.props.drawlistReducer.map(type => (
                                <div className="v-item" key={type}>{type}</div>
                            ))}
                        </Carousel>
                    </div>
                }

            </div>
        </div>
    }
}
export default TurnTable;