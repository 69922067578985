import { combineReducers  } from 'redux'
import { getworklistDemo,
    check_work_detail,
    uoloadworks,
    checkrankinglist,
    initlasttime,
    voteworkReducer,
    signinfoReducer,
    publishworkReducer,
    uploadfileReducer,
} from './reducers/index_reducers'
import { inithavetimereducer,drivesignupreducer } from './reducers/drive_reducers'
import { initturntablelist,turnliverReducer,drawlistReducer,detailgiftReducer } from './reducers/turntable_reducer'
export default combineReducers({
    getworklistDemo,
    check_work_detail,
    uoloadworks,
    checkrankinglist,
    inithavetimereducer,
    drivesignupreducer,
    initlasttime,
    voteworkReducer,
    signinfoReducer,
    publishworkReducer,
    uploadfileReducer,
    initturntablelist,
    turnliverReducer,
    drawlistReducer,
    detailgiftReducer
})