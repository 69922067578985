import React from 'react'
import Style from './driver_toast.less'
class DriverTaost extends React.Component {
    render() {
        return (
            <div className={Style.toast_content}>
                <h3>报名成功！</h3>
                <h3>楚天都市信园2020“名城好司机，文明荆州人”</h3>
                <p>楚天都市信园邀请您于5月30日-6月20日至楚天都市信园营销中心确认报名，领取报名礼品。5月30日前300名确认报名，可领取100元油卡。</p>
                <p>信园营销中心地址：荆北新区发展大道与五台东路交汇处</p>
                <p>咨询热线：0716-8138 999 </p>
                <p style={{paddingTop:'40px'}}>本活动解释权归荆州中大豪盛集团所有。</p>
            </div>
        )
    }
}
export default DriverTaost;