import React from 'react'
import Style from './vote-charts.less'
import { Tabs, Badge,PullToRefresh, ListView,Toast  } from 'antd-mobile';
import { connect } from 'react-redux'
import { withRouter, BrowserRouter as Router } from "react-router-dom"
import { getworklistDemo,rankinglistDemo } from '../../../store/actions/index_actions'
//作品展
import WorksExhibition from './works-exhibition/works-exhibition.jsx'
//规则
import WorksRule from './works-rule/works-rule.jsx'
//排行榜
import WorkChart from './works-chart/works-chart.jsx'
@connect(
    state => ({
        initlasttime: state.initlasttime,
    }),
    dispatch => ({
        getworklistDemo: (data) => dispatch(getworklistDemo(data)),
        rankinglistDemo:(data) => dispatch(getworklistDemo(data))
     })
)
class VoteCharts extends React.Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });
    }
    state = {
        tabs: [
            { title: <Badge>作品展</Badge> },
            { title: <Badge>排行榜</Badge> },
            { title: <Badge>参赛规则</Badge> },
        ],
        params:{
            currentpage:1,
            limit:10000,
        },
    }
    componentDidMount(){
        this.props.getworklistDemo(this.state.params)
    }
    initTabs() {
        if (this.props.initlasttime !== null) {
            if (this.props.initlasttime.activeStatus == '未开始') {
                return <Tabs tabs={[{ title: <Badge>参赛规则</Badge> }]}>
                    <div>
                        <WorksRule />
                    </div>
                </Tabs>
            } else {
                // return <Tabs tabs={this.state.tabs} initialPage={0} onChange={this.changeResult.bind(this)}>
                return <Tabs tabs={this.state.tabs} initialPage={0}>
                    <div style={{paddingBottom: '20px'}} className={Style.photos}>
                        <WorksExhibition />
                    </div>
                    <div style={{paddingBottom: '20px'}}>
                        <WorkChart />
                    </div>
                    <div style={{paddingBottom: '20px'}}>
                        <WorksRule />
                    </div>
                </Tabs>
            }
        }
    }
    render() {
        return (
            <div style={{ marginTop: '20px' }}>
                {
                    this.props.initlasttime == null ? null : this.initTabs()
                }
            </div>
        )
    }
}

export default withRouter(VoteCharts);