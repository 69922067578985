
//mockdemo
export const REQUIRE_DEMO = 'require_demo'

//获取作品列表
export const WORK_LIST = 'work_list'
//查看作品详情/个人作品
export const WORK_DETAIL = 'work_detail'
//上传个人作品
export const UP_LOAD_WORKS = 'up_load_works'
//获取排名
export const CHECK_RANKING_LIST = 'check_ranking_list'
//检测是否报名,活动是否开始
export const INIT_HAVE_TIME = 'init_have_time'
//报名接口
export const DRIVE_SIGN_UP = 'drive_sign_up'
//获取投票倒计时
export const LAST_TIME = 'last_time'
//获取当前活动状态
export const VOTE_STATE = 'vote_state'
//点击投票
export const VOTE_WORK = 'vote_work'
//填写业
export const SIGN_INFO = 'sign_info'
//发布摄影作品
export const PUBLISH_WORK = 'publish_work'
//上传图片
export const UP_LOAD_FILE = 'up_load_file'
//上传投票作品
export const INIT_TURN_TABLE_LIST = 'init_trun_table_list'
//转盘验证是不是业主
export const TURN_LIVER = 'trun_liver'
//点击抽奖显示结果
export const CLICK_DRAW = 'click_draw'
//抽奖结果显示列表
export const SHOW_DRAW_LIST = 'show_draw_list'
//显示抽奖礼品详情
export const DETAIL_GIFT = 'detail_gift'
