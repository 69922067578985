import React from 'react';
import Style from './driver_rule.less'
import { Toast } from 'antd-mobile'
import store from '../../store/store'
import { inithavetimeMethod } from '../../store/actions/drive_actions'
import { connect} from 'react-redux'
import { bindActionCreators} from 'redux'
@connect(
    state=>({
        inithavetimereducer : state.inithavetimereducer
    }),
    dispatch=>({
        inithavetimeMethod: () => dispatch(inithavetimeMethod())
      })
    
)
class DriverRules extends React.Component {
    constructor(props){
        super(props)
    }
    componentDidMount(){
        this.props.inithavetimeMethod()
    }
    initActiveTime(){
        const endTime = new Date(this.props.inithavetimereducer.activityMarketing.endTime).getTime();
        if( new Date().getTime() > endTime){
            Toast.info('活动已结束')
            return;
        }
        if(this.props.inithavetimereducer.driverInfo !== null){
            this.props.history.push('/driver_toast')
            // Toast.info('已参与报名');
            return;
        }
        this.props.history.replace('/driver_form')
    }
    render() {
        return (
            <div className={Style.inner_ac}>
                <div className={Style.payment_activities}>
                    <div className={Style.agreement}>
                        <div className= {Style.agreement_title}>楚天都市信园2020“名城好司机 文明荆州人”</div>
                        <div className={Style.agreement_content}>
                            <div className={Style.agreement_content_item}>
                                <span>活动规则</span>
                            </div>
                            <div className={Style.agreement_content_item}>
                                <p>1、本活动采取网络预报名和现场确认报名相结合形式进行，车主在中大豪盛微信平台“楚天云家”线上报名，5月30日-6月20日间报名车主持报名车辆行驶证原件，到楚天都市信园营销中心确认报名信息后即为报名成功，<span style={{color:'red'}}>只在网络报名没有进行现场确认者，报名信息无效报名失败</span>。</p>
                            </div>
                            <div className={Style.agreement_content_item}>
                                <p>2、5月30日现场报名确认信息成功车主领取活动车标一枚，，将之粘贴于车辆后玻璃处。<span style={{color:'red'}}>前300名车主可领取100元油卡一张</span>，颁奖典礼当日，车标完整无损，可在楚天都市信园营销中心再领取100元油卡(仅限5月30日前300名贴标车主)。</p>
                            </div>
                           
                            <div className={Style.agreement_content_item}>
                                <p>3、活动记录考核期结束后，没有发生新生记录的车辆，自动晋级成为颁奖礼入围车辆，可以参加最后的抽奖环节，现场领取抽奖券，抽取特等奖一名（奖金5000元油卡）、一等奖三名（每人800元油卡）、二等奖四名（每人500元油卡）、三等奖十名（每人200元油卡）。</p>
                            </div>
                            <div className={Style.agreement_content_item}>
                                <p>本活动最终解释权归荆州中大豪盛集团所有。</p>
                            </div>
                           
                        </div>
                    </div>
                    <div className={Style.payment_activities_button}>
                        <div className={Style.payment_activities_nav} onClick={this.initActiveTime.bind(this)}>
                            <span>我已阅读，同意此协议</span>
                        </div>
                    </div>
                </div>
                {/* <div className="none_image" wx: else>
                <image src="https://chor-young-1300991683.cos.ap-nanjing.myqcloud.com/mini/wait1.jpg" mode="widthFix" />
            </div> */}
            </div >
        )
    }
}
export default DriverRules;