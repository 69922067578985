import React from 'react'
import Style from './turnresult.less'
import { connect } from 'react-redux'
import { detailgiftMethod } from '../../store/actions/truntable_action'
import ResultBgc from '../../static/images/truntable/resultbgc.png'
import Result from '../../static/images/truntable/result.png'
import Logo from '../../static/images/truntable/logo.png'
import Img1 from '../../static/images/truntable/img1.png'
import Img2 from '../../static/images/truntable/img2.png'
import Card from '../../static/images/truntable/card.png'
import { copySync } from 'fs-extra'
@connect(
    state => ({
        detailgiftReducer: state.detailgiftReducer,
    }),
    dispatch => ({
        detailgiftMethod: (data) => dispatch(detailgiftMethod(data)),
    })
)
class TurnResult extends React.Component{
    constructor(props) {
        super(props)
    }
    componentDidMount(){
        if(this.props.location.state == undefined){
            this.props.detailgiftMethod(getUrlkey(this.props.location.search).giftid)
        }else{
            this.props.detailgiftMethod(this.props.location.state.id)
        }
        //  window.wx.miniProgram.switchTab({
        //                 url:'/pages/index/index'
        //             })
  
    }
    componentWillMount(){
        // window.wx.miniProgram.switchTab({
        //             url:'/pages/index/index'
        // })
       
    }
    render(){
        return(
            <>
            {
                 this.props.detailgiftReducer == null ? null :  <div className={Style.result} style={{backgroundImage:`url(${ResultBgc})`}}>
                 <div className={Style.result_logo}>
                     <img src={Logo} alt=''/>
                 </div>
                 <div className={Style.result_content}>
                     {
                         this.props.detailgiftReducer.receiveStatus == 0 ?  null : <img src={Card} alt='' className={Style.card_img}/>
                     }
                    
                     <div className={Style.result_inner}>
                     {
                         this.props.detailgiftReducer.receiveStatus == 0 ?  null :  <div className={Style.result_mask}></div>
                     }
                        
                         <div className={Style.result_inner_title}>
                                <p style={{color:'#bf5757'}}>恭喜您抽中了</p>
                                <p style={{color:'#f04949'}}><b>{this.props.detailgiftReducer.giftName}</b></p>
                         </div>
                         <div className={Style.result_inner_img}>
                             <img src={this.props.detailgiftReducer.image} alt=''/>
                                <p>恭喜您了获得壹号院业主专属福利 <b style={{color:'#f04949',fontSize:'14px'}}>{this.props.detailgiftReducer.giftName}</b>,待活动仪式结束后，请带上身份证进入园区里领取礼品领奖时间：2020年6月6日-2020年6月7日</p>
                             <p>（本活动最终解释权归荆州中大豪盛置业有限公司所有）</p>
                         </div>
                     </div>
                     <img src={Result} alt="" className={Style.result_img}/>
                     <img src={ Img1 }  alt="" className={Style.result_img1}/>
                     <img src={ Img2 }  alt="" className={Style.result_img2}/>
                     
                 </div>
             </div>
            }
            </>
           
        )
    }
}
function getUrlkey(url) {
    var params = {};
    if(url.indexOf("?") != -1) {
      var urls = url.split("?");
      var arr = urls[1].split("&");
      for(var i = 0, l = arr.length; i < l; i++) {
        var a = arr[i].split("=");
        params[a[0]] = a[1];
      }
    } else {}
    return params;
  }
export default TurnResult;