import React from 'react'
import { List, InputItem, WhiteSpace, Toast,Button } from 'antd-mobile';
import { createForm } from 'rc-form';
import Style from './driver_form.less'
import { connect} from 'react-redux'
import { drivesignupMethod } from '../../store/actions/drive_actions'
@connect(
    state=>state,
    dispatch=>({
        drivesignupMethod: (data) => dispatch(drivesignupMethod(data))
      })
)
class DriverForm extends React.Component{
    constructor(props){
        super(props)
    }
    handleSubmitsubmit = () => {

        this.props.form.validateFields((error, value) => {
            if(value.name == undefined){
                this.onErrorClick('请输入姓名')
                return;
            }
            if(value.mobile == undefined){
                this.onErrorClick('请输入手机号')
                return;
            }
            if(value.mobile.replace(/\s/g, '').length !== 11){
                this.onErrorClick('请输入手机号')
                return;
            }
            if(value.licPlaNum == undefined){
                this.onErrorClick('请输入车牌号')
                return;
            }
            value.mobile =  value.mobile.replace(/\s/g, '')
            value.actId = localStorage.getItem('actId');
            value.cusId = localStorage.getItem('id');
            this.props.drivesignupMethod(value)
        });
    }
    //错误信息提示
    onErrorClick = (title) => {
        Toast.info(title,1);
      }
    render(){
        const { getFieldProps } = this.props.form;
        return(
            <div className={Style.driver}>
                  <List renderHeader={() => '车主姓名'}>
                    <InputItem
                        {...getFieldProps('name',{})}
                        placeholder="请输入姓名"
                    />
                   </List>
                   <WhiteSpace/>
                   <List renderHeader={() => '联系电话'}>
                   <InputItem
                        type="phone"
                        {...getFieldProps('mobile')}
                        placeholder="请输入手机号"
                    />
                    </List>
                    <WhiteSpace/>
                   <List renderHeader={() => '车牌号'}>
                    <InputItem
                        {...getFieldProps('licPlaNum')}
                        placeholder="请输入车牌号"
                    />
                   </List>
                   <div className={Style.button}>
                        <Button type="primary" className={Style.button_confirm} onClick={this.handleSubmitsubmit}>确认</Button>
                   </div>
            </div>
        )
    }
}
export default createForm()(DriverForm);