
import React from 'react'
import { List, InputItem, WhiteSpace, Toast, Button, Picker } from 'antd-mobile';
import { createForm } from 'rc-form';
import Style from '../driver_form/driver_form.less'
import { connect } from 'react-redux'
import { turnliverMethod } from '../../store/actions/truntable_action'
import { location } from '../../static/images/truntable/location1.png'
import WxImageViewer from 'react-wx-images-viewer/dist/components/WxImageViewer';
@connect(
    state => state,
    dispatch=>({
        turnliverMethod: (data) => dispatch(turnliverMethod(data)),
      })
)
class TurnForm extends React.Component {
    constructor(props) {
        super(props);
    }
  
    handleSubmitsubmit = () => {
        this.props.form.validateFields((error, value) => {
            console.log(value)
            if (value.name == undefined) {
                this.onErrorClick('请输入姓名')
                return;
            }
            if (value.phone == undefined) {
                this.onErrorClick('请输入手机号')
                return;
            }
            if (value.phone.replace(/\s/g, '').length !== 11) {
                this.onErrorClick('请输入正确的手机号码')
                return;
            }
            value.phone =  value.phone.replace(/\s/g, '')
            // value.longitude = "119.38189313745119";
            // value.latitude = "32.39355525217179";
            value.longitude = localStorage.getItem('longitude');
            value.latitude = localStorage.getItem('latitude');
            value.wxToken = localStorage.getItem('Token');
            this.props.turnliverMethod(value)
        });
    }
    //错误信息提示
    onErrorClick = (title) => {
        Toast.info(title, 1);
    }
    render() {
        const { getFieldProps } = this.props.form;
        return (
            <div className={Style.driver} style={{paddingTop:'10px',boxSizing:'border-box'}}>
                <List renderHeader={() => '业主姓名'}>
                    <InputItem
                        {...getFieldProps('name')}
                        placeholder="请输入姓名"
                    />
                </List>
                <WhiteSpace />
                <List renderHeader={() => '联系电话'} style={{paddingTop:'10px'}}>
                    <InputItem
                        {...getFieldProps('phone',{initialValue: localStorage.getItem('phone')})}
                        placeholder="请输入手机号"
                    />
                </List>
                <List renderHeader={() => '当前位置'} style={{paddingTop:'10px'}}>
                    <div className={Style.location}>
                        <span>{decodeURI(localStorage.getItem('city'))}</span>
                    </div>
                </List>
                <WhiteSpace />
                <div className={Style.tips} style={{color:'red'}}>
                    <p>㊟:手机号需和购房时号码一致！</p>
                </div>
                <div className={Style.button}>
                    <Button type="primary" className={Style.button_confirm} onClick={this.handleSubmitsubmit}>确认</Button>
                </div>
            </div>
        )
    }
}
export default createForm()(TurnForm);