import React from 'react'
import Style from './chart-exhibition.less'
import demo from '../../static/images/vote-page/demo.png'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
@connect(
    state => ({
        initlasttime: state.initlasttime
    }),
)
class ChartExhibition extends React.Component {
    constructor(props) {
        super(props);
    }
    nave_to_work_detail(workid) {
        console.log(workid);
        this.props.history.push({
            pathname: '/vote-preview',
            state: {
                workid: workid
            }
        })
    }

    render() {
        const item = this.props.item;
        return (
            <div className={Style.chart_exhibition}>
                <div className={Style.chart_innner}>
                    <div className={Style.chart_innner_content} onClick={this.nave_to_work_detail.bind(this, item.id)} style={{backgroundImage:`url(${item.imgList[0].url})`}}>
                        {/* <img src={item.imgList[0].url} /> */}
                        <div className={Style.chart_innner_mask}>
                            <div className={Style.chart_innner_mask_list}>
                                <span>编号</span>
                                <span>{item.id}</span>
                            </div>
                            {
                                item.votesNum == null ? null : <div className={Style.chart_innner_mask_list}>
                                    <span>总票数</span>
                                    <span>{item.votesNum == null ? '0' : item.votesNum}</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={Style.chart_name}>
                        <span>《{item.name}》</span>
                    </div>
                    <div className={Style.chart_author}>
                        <span>{item.author}</span>
                    </div>
                    <div className={Style.chart_oper}>
                        <div className={Style.chart_oper_button}>
                            {
                                this.props.initlasttime.activeStatus == '已结束' ? <div className={`${Style.chart_oper_button_submit} ${Style.none}`} >点击投票</div> : <div className={Style.chart_oper_button_submit} onClick={this.props.voteClick}>点击投票</div>
                            }
                            {/* <div className={Style.chart_oper_button_submit} onClick={this.props.voteClick}>点击投票</div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ChartExhibition);