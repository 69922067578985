import React from 'react'
import WxImageViewer from 'react-wx-images-viewer';
import Style from './vote-preview.less'
import Head from '../../static/images/vote-page/head.png'
import HeadTopImg from '../../static/images/vote-page/headtop.png'
import BgcImg from '../../static/images/vote-page/bgc.png'
import Demo from '../../static/images/vote-page/demo.png'
import store from '../../store/store'
import { connect } from 'react-redux'
import { CheckDetailDemo } from '../../store/actions/index_actions'
@connect(
    state => ({
        check_work_detail: state.check_work_detail,
    }),
    dispatch=>({
        CheckDetailDemo: (data) => dispatch(CheckDetailDemo(data))
    })
    // dispatch=>bindActionCreators({getworklistDemo},dispatch)
)

class VotePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imags: [],
            index: 0,
            isOpen: false,
        }
    }
    componentDidMount() {
        console.log(this.props.location.state)
        store.dispatch(CheckDetailDemo(this.props.location.state.workid))
        //获取项目
    }
    onClose = () => {
        this.setState({
            isOpen: false
        })
    }

    openViewer(index) {
        this.setState({
            index,
            isOpen: true
        })
    }
    render() {
        const {
            imags,
            index,
            isOpen
        } = this.state;
        const detail = store.getState().check_work_detail;
        return (
            <div className={Style.votePreview}>
                {
                    console.log(this.props.check_work_detail)
                }

                {
                    store.getState().check_work_detail == null ? null : <>
                        {
                            detail.ranking == null ? null : <div className={Style.votePreview_top}>
                                <div className={Style.votePreview_top_innner}>
                                    <div className={Style.Head_box}>
                                        <img src={Head} className={Style.Head} />
                                    </div>
                                    <img src={HeadTopImg} className={Style.HeadTopImg} />
                                    <div className={Style.vote_info}>

                                        <div className={Style.vote_info_list}>
                                            <span style={{ fontSize: '50px' }}>{detail.ranking}</span>
                                            <span>排名</span>
                                        </div>
                                        <div className={Style.vote_info_list}>
                                            <span style={{ fontSize: '50px' }}>{detail.votesNum == null ? '0': detail.votesNum}</span>
                                            <span>票数</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={Style.votePreview_content}>
                            <div className={Style.votePreview_content_imgs}>
                                <img src={BgcImg} className={Style.BgcImg} />
                                <div className={Style.demoImg} onClick={this.openViewer.bind(this, 0)} style={{backgroundImage:`url(${detail.imgList[0].url})`}}>
                                    {/* <img src={detail.imgList[0].url} className={Style.demoImg_img} /> */}
                                </div>
                            </div>
                            <div className={Style.votePreview_decri}>
                    <h3>《{detail.name}》</h3>
                                <p className={Style.votePreview_victor_name}>{detail.author}</p>
                                <p className={Style.votePreview_victor}>{detail.content}</p>
                            </div>
                        </div>
                        {
                            isOpen ? <WxImageViewer onClose={this.onClose} urls={detail.imgList.map((item,index)=>{
                                return item.url
                            })} index={index} /> : ""
                        }
                    </>
                }

            </div>
        )
    }
}
export default VotePreview;