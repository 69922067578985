//拦截器的配置
import Axios from 'axios'
// import { AlertMethod } from '../store/actions/alertActions'
import store from '../store/store'
import history from '../store/history'
import { Toast} from 'antd-mobile';
// import { message } from 'antd'
Axios.defaults.withCredentials = true;
Axios.defaults.baseURL = "https://mp.choryoung.com";
// Axios.defaults.baseURL = "http://192.168.1.30:8085";
// Axios.defaults.baseURL = "http://47.111.17.163:8001";
//"proxy":"http://192.168.1.30:8080/project"
// "proxy": "http://192.168.1.30:8001"
Axios.defaults.timeout = 100000;
Axios.interceptors.request.use(
    config=>{
        if(localStorage.getItem("Token")){
            config.headers.Authorization = localStorage.getItem("token");
          }
          return config
    },error=>{
       return Promise.reject(error)
    }
)
Axios.interceptors.response.use(
    response=>{
        if(response.data.code === '000000' || response.data.ret == 0){
           
        }else if(response.data.code === "300001"){
            Toast.info(response.data.message, 1);
            // store.dispatch(AlertMethod(true,response.data.message,'error'))
            // setTimeout(()=>{
            //     store.dispatch(AlertMethod(false,'',''))
            // },3000)
        }else if(response.data.code === "500001"){
            Toast.info(response.data.message, 1);
        }else if(response.data.code === "600001"){
            Toast.info(response.data.message, 1);
        }else{
            Toast.info(response.data.message, 1);
        }
        return response;
    },error=>{
        return Promise.reject(error)
    }
)
export default Axios;

