import {
    WORK_LIST,
    WORK_DETAIL,
    UP_LOAD_WORKS,
    CHECK_RANKING_LIST,
    LAST_TIME,
    VOTE_WORK,
    SIGN_INFO,
    PUBLISH_WORK,
    UP_LOAD_FILE,
} from '../actionTypes'
import axios from 'axios'
import qs from 'qs'
import history from '../history'
import '../../common/axios'
import { uoloadworks } from '../reducers/index_reducers'
import { Toast } from 'antd-mobile'
//查看作品集
const getworklist = (data) => ({
    type: WORK_LIST,
    data: data
})
let getworklistDemo = (params) => {
    return (dispatch, getState) => {
        axios({
            url: "/java/works/list",
            method: 'POST',
            data: params
        }).then(res => {
            if (res.data.code === '000000') {
                const data = res.data.data;
                const action = getworklist(data);
                dispatch(action);  //redux-thunk支持dispatch作为参数
            }
        }).catch(err => {
            console.log(err)
        })
    }
}

//查看个人作品
const CheckDetail = (data) => ({
    type: WORK_DETAIL,
    data: data
})
let CheckDetailDemo = (params) => {
    console.log(params)
    return (dispatch, getState) => {
        axios({
            url: "/java/works/" + params,
            method: 'GET',
        }).then(res => {
            console.log(res.data)
            if (res.data.code === '000000') {
                const data = res.data.data;
                const action = CheckDetail(data);
                dispatch(action);  //redux-thunk支持dispatch作为参数
            }
        }).catch(err => {
            console.log(err)
        })
    }
}
//上传个人作品
const uploadworks = (data) => ({
    type: UP_LOAD_WORKS,
    data: data
})
let uploadworksDemo = (params) => {
    console.log(params)
    return (dispatch, getState) => {
        axios({
            url: "/java/works/add",
            method: 'POST',
            data:params,
        }).then(res => {
            console.log(res)
            if (res.data.code === '000000') {
                const data = res.data.data;
                const action = uploadworks(data);
                dispatch(action);  //redux-thunk支持dispatch作为参数
                history.replace({
                    pathname:'/vote-preview',
                    state:{
                        workid:res.data.data.worksId
                    },
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }
}
//查看排行榜
const rankinglist = (data) => ({
    type: CHECK_RANKING_LIST,
    data: data
})
let rankinglistDemo = (params) => {
    return (dispatch, getState) => {
        axios({
            url: "/java/works/order/list",
            method: 'GET',
            params: params
        }).then(res => {
            if (res.data.code === '000000') {
                const data = res.data.data;
                const action = rankinglist(data);
                dispatch(action);  //redux-thunk支持dispatch作为参数
            }
        }).catch(err => {
            console.log(err)
        })
    }
}
//获取时间倒计时
const initlasttime = (data) => ({
    type: LAST_TIME,
    data: data
})
let initlasttimeDemo = (params) => {
    return (dispatch, getState) => {
        axios({
            url: "/java/config",
            method: 'GET',
            params:params
        }).then(res => {
            if (res.data.code === '000000') {
                const data = res.data.data;
                const action = initlasttime(data);
                dispatch(action);  //redux-thunk支持dispatch作为参数
            }
        }).catch(err => {
            console.log(err)
        })
    }
}

//点击投票
const votework = (data) => ({
    type: VOTE_WORK,
    data: data
})
let voteworkMethod = (params, data = Object) => {
    return (dispatch, getState) => {
        axios({
            url: "/java/userRecord/" + params,
            method: 'POST',
            data: data
        }).then(res => {
            if (res.data.code === '000000') {
                const data = res.data.data;
                const action = votework(data);
                dispatch(action);
                Toast.success('投票成功')
            } else if (res.data.code === '400001') {
                Toast.info('投票次数已用完')
            }
        }).catch(err => {
            console.log(err)
        })
    }
}
//SIGN_INFO 填写业主信息
const signinfo = (data) => ({
    type: SIGN_INFO,
    data: data
})
let signinfoMethod = (params) => {
    console.log(params)
    return (dispatch, getState) => {
        axios({
            url: "/java/owner/check",
            method: 'GET',
            params: params
        }).then(res => {
            if (res.data.code === '000000') {
                const data = res.data.data;
                const action = signinfo(data);
                dispatch(action);
                if (data.passed === 0) {
                    Toast.info(data.desc)
                    setTimeout(() => {
                        history.goBack()
                    }, 1000)
                } else {
                    setTimeout(() => {
                        history.replace('/vote-form')
                    }, 500)
                }
            } else {
                Toast.info(res.data.data.message) //redux-thunk支持dispatch作为参数
            }
        }).catch(err => {
            console.log(err)
        })
    }
}
//发布作品PUBLISH_WORK
const publishwork = (data) => ({
    type: SIGN_INFO,
    data: data
})
let publishworkMethod = (params) => {
    console.log(params)
    return (dispatch, getState) => {
        axios({
            url: "/java/owner/check",
            method: 'GET',
            params: params
        }).then(res => {
            if (res.data.code === '000000') {
                const data = res.data.data;
                const action = publishwork(data);
                dispatch(action);
                if (data.passed === 0) {
                    Toast.info(data.desc)
                    setTimeout(() => {
                        history.goBack()
                    }, 1000)
                } else {
                    setTimeout(() => {
                        history.replace('/vote-form')
                    }, 500)
                }
            } else {
                Toast.info(res.data.data.message) //redux-thunk支持dispatch作为参数
            }
        }).catch(err => {
            console.log(err)
        })
    }
}

//上传图片 UP_LOAD_FILE
//点击投票
const uploadfile = (data) => ({
    type: UP_LOAD_FILE,
    data: data
})
var newArray = [];
let uploadfileMethod = (data,cb) => {
    return (dispatch, getState) => {
        axios({
            url: "/java/resource/file/upload",
            method: 'POST',
            data: data
        }).then(res => {
            if (res.data.code === '000000') {
                const data = res.data.data.id;
                localStorage.setItem('pic',data);
                newArray.push(data);
                const action = uploadfile(newArray);
                dispatch(action);
            } else {
                Toast.info('上传失败')
            }
        }).catch(err => {
            console.log(err)
        })
    }
}

//删除newArray中的某一项
let deletaArray = (index)=>{
    return (dispatch, getState) =>{
        newArray.shift(index,0);
        const action = uploadfile(newArray);
        dispatch(action);
    } 
}
export {
    getworklistDemo,
    CheckDetailDemo,
    uploadworksDemo,
    rankinglistDemo,
    initlasttimeDemo,
    voteworkMethod,
    signinfoMethod,
    publishworkMethod,
    uploadfileMethod,
    deletaArray
};