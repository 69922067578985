import React from 'react'
import Style from './works-rule.less'
const WorksRule = (props) => (
   <div className={Style.work_rule}>
      <p className={Style.work_rule_title}>作品网络投票时间及领奖时间：</p>
      <div className={Style.work_rule_content}>
         <p>投票时间：2020年6月6日-2020年6月19日20：00 </p>
         <p>领奖时间：2020年6月20日 </p>
      </div>
      <p className={Style.work_rule_title}>参与规则：</p>
      <div className={Style.work_rule_content}>
         <p>1、参赛者身份不限。</p>
         <p>2、登录/注册楚天云家小程序，进入报名通道。在提交摄影作品时，需登记真实姓名和电话，等待审核。</p>
      </div>
      <p className={Style.work_rule_title}>参赛作品要求：</p>
      <div className={Style.work_rule_content}>
         <p>1、参赛作品需与“楚天都市·壹号院”相关。以反映楚天都市·壹号院自然园林景观为主，可通过园林布景等场景辅助表现。</p>
         <p>2、作品健康，不得含有反对国家或政府的内容，不得泄露商业机密、侵犯他人隐私、侮辱或诽谤他人人格等内容。</p>
         <p>3、作者对作品版权及肖像权的合法性负责。</p>
         <p>4、主办方对所参赛作品具有使用权。</p>
      </div>
      <p className={Style.work_rule_title}>作品评选及奖励方式：</p>
      <div className={Style.work_rule_content}>
         {/* <p style={{color:" #2d7697"}}>所有投稿作品通过初选选出40张入围作品，由中大豪盛组织专家评委选出</p> */}
         <p>1、参赛者作品采取线上投票评选， 每个ID每日可投三票。</p>
         <p>2、2020年6月19日20:00截止投票，投票总数最高前10名分别获得以下奖项:</p>
         <p>一等奖1名: <span style={{color:'red'}}>大疆专业航拍无人机</span></p>
         <p>二等奖3名: <span style={{color:'red'}}>小米空气净化器pm2.5</span></p>
         <p>三等奖6名: <span style={{color:'red'}}>小米扫地机器人.5</span></p>
      </div>
   </div>
)
export default WorksRule;