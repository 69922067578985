
import React from 'react'
import { List, InputItem, WhiteSpace, Toast, Button, Picker } from 'antd-mobile';
import { createForm } from 'rc-form';
import Style from './vote-sign-up.less'
import { connect } from 'react-redux'
import { signinfoMethod } from '../../store/actions/index_actions.js'
// const  district=[
//     {
//         label: '楚天都市.壹号院',
//         value: '1',
//     },
//     {
//         label: '楚天都市.诚园',
//         value: '2',
//     },
//     {
//         label: '楚天都市.朗园',
//         value: '3',
//     },
//     {
//         label: '楚天都市.佳园',
//         value: '4',
//     },
// ]
@connect(
    state => state,
    dispatch=>({
        signinfoMethod: (data) => dispatch(signinfoMethod(data)),
      })
)
class VoteSignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentpicker: ['1'],
        }
    }
    handleSubmitsubmit = () => {
        this.props.form.validateFields((error, value) => {
            if (value.name == undefined) {
                this.onErrorClick('请输入姓名')
                return;
            }
            if (value.phone == undefined) {
                this.onErrorClick('请输入手机号')
                return;
            }
            if (value.phone.replace(/\s/g, '').length !== 11) {
                this.onErrorClick('请输入手机号')
                return;
            }
            value.name = value.name.replace(/\s/g, '')
            value.phone =  value.phone.replace(/\s/g, '')
            localStorage.setItem('name',value.name);
            localStorage.setItem('phone',value.phone);
            this.props.signinfoMethod(value)
        });
    }
    //错误信息提示
    onErrorClick = (title) => {
        Toast.info(title, 1);
    }
    onChangecurrentpicker(value) {
        this.setState({
            currentpicker: value,
        });
    }
    render() {
        const { getFieldProps } = this.props.form;
        return (
            <div className={Style.driver}>
                <List renderHeader={() => '参赛者姓名'}>
                    <InputItem
                        {...getFieldProps('name')}
                        placeholder="请输入姓名"
                    />
                </List>
                <WhiteSpace />
                <List renderHeader={() => '参赛者联系电话'}>
                    <InputItem
                        type="phone"
                        {...getFieldProps('phone')}
                        placeholder="请输入手机号"
                    />
                </List>
                {/* <WhiteSpace />
                <List renderHeader={() => '楼盘名称'}>
                    <Picker data={district} cols={1} value={this.state.currentpicker} className="forss">
                        <List.Item arrow="horizontal" style={{opacity:0.65}}>
                            <span style={{fontSize:"14px",color:'rgba(45, 118, 151, 1)'}}>请选择楼盘</span>
                            </List.Item>
                    </Picker>
                </List> */}
                {/* <List renderHeader={() => '楼栋号'}>
                    <InputItem placeholder="请输入楼栋号" />
                </List>
                <div className={Style.tips}>
                    <p>Tips:如是业主,请输入具体居住地址</p>
                </div> */}
                <div className={Style.button}>
                    <Button type="primary" className={Style.button_confirm} onClick={this.handleSubmitsubmit}>确认</Button>
                </div>
            </div>
        )
    }
}
export default createForm()(VoteSignUp);