import { INIT_TURN_TABLE_LIST,TURN_LIVER,SHOW_DRAW_LIST,DETAIL_GIFT } from '../actionTypes'
import axios from 'axios'
import '../../common/axios'
import { Toast } from 'antd-mobile'
import history from '../history'

//查看奖品列表
const inittable = (data) =>({
    type: INIT_TURN_TABLE_LIST,
    data:data
})
let inittableMethod = (params) => {
    return (dispatch, getState) => {
        axios({
            url: "/ctds/gifts/wx/getGift",
            method: 'GET',
            params:params
        }).then(res => {
            console.log(res.data)
            if(res.data.ret == 0){
                const data = res.data.retData;
                const action = inittable(data);
                dispatch(action);  //redux-thunk支持dispatch作为参数
            }
        }).catch(err => {
            console.log(err)
        })
    }
}
//查看是否是业主
const turnliver = (data) =>({
    type: TURN_LIVER,
    data:data
})
let turnliverMethod = (params) => {
    return (dispatch, getState) => {
        axios({
            url: "/ctds/onlineActs/wx/veride",
            method: 'POST',
            data:params
        }).then(res => {
            console.log(res.data)
            if(res.data.ret == 0){
                const data = res.data.retData;
                const action = turnliver(data);
                dispatch(action);  //redux-thunk支持dispatch作为参数
                history.replace('/turn_table')
                // if(data.state == 1){
                //     history.replace({
                //         pathname:'/turn_result',
                //         state:{
                //             id:res.data.retData.id
                //         }
                //     })
                // }else{
                //     history.replace('/turn_table')
                // }
            }
        }).catch(err => {
            console.log(err)
        })
    }
}
//中奖列表
const drawlist = (data) =>({
    type: SHOW_DRAW_LIST,
    data:data
})
let drawlistMethod = () => {
    return (dispatch, getState) => {
        axios({
            url: "/ctds/giftRecords/wx/getRotaryTableWinning",
            method: 'GET',
        }).then(res => {
            if(res.data.ret == 0){
                const data = res.data.retData;
                const action = drawlist(data);
                dispatch(action);  //redux-thunk支持dispatch作为参数
                history.push('/turn_table')
            }
        }).catch(err => {
            console.log(err)
        })
    }
}
//领取礼品详情
const detailgift = (data) =>({
    type: DETAIL_GIFT,
    data:data
})
let detailgiftMethod = (params) => {
    return (dispatch, getState) => {
        axios({
            url: "/ctds/giftRecords/wx/getRotaryTableList",
            method: 'GET',
            params:{
                id:params
            }
        }).then(res => {
            if(res.data.ret == 0){
                const data = res.data.retData;
                const action = detailgift(data);
                dispatch(action);  //redux-thunk支持dispatch作为参数
            }
        }).catch(err => {
            console.log(err)
        })
    }
}
export { inittableMethod,turnliverMethod,drawlistMethod,detailgiftMethod };