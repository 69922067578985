import React from 'react'
import Style from './vote-list.less'
import ButtonStyle from '../../../components/button/button'
import { withRouter } from 'react-router-dom';
import { InputItem } from 'antd-mobile';
import { connect } from 'react-redux'
import SearchImg from "../../../static/images/vote-page/search.png";
import {getworklistDemo} from '../../../store/actions/index_actions'
@connect(
    state => ({
        getworklistDemo:state.getworklistDemo,
        initlasttime: state.initlasttime
    }),
    dispatch=>({
        getworklistDemo: (data) => dispatch(getworklistDemo(data))
      })
)
class VoteList extends React.Component {
    constructor(props) {
        super(props);
        this.gotakepart = this.gotakepart.bind(this)
    }
    state= {
        searchVotorNum :'',
    }
    //去参加比赛
    gotakepart() {
        if(this.props.initlasttime.activeStatus == '进行中'){
            if(this.props.initlasttime.worksId == null){
                this.props.history.push('/vote-sign-up')
            }else{
                this.props.history.push({
                    pathname:'/vote-preview',
                    state:{
                        workid: this.props.initlasttime.worksId
                    }
                })
            }
           
        }else if(this.props.initlasttime.activeStatus == '已结束'){
            console.log(this.props.initlasttime)
            if(this.props.initlasttime.worksId == !null){
                this.props.history.push({
                    pathname:'/vote-preview',
                    state:{
                        workid: this.props.initlasttime.worksId
                    }
                })
            }
           
        }
    }
    changInputVotor(e){
        this.setState({
            searchVotorNum : e.replace(/\s+/g, '')
        })
    }
    //搜索参赛者编号
    searchVotor(){
        this.props.getworklistDemo({
            current:1,
            limit:10,
            worksId: this.state.searchVotorNum
         });
    }
    initstateButton(){
        if(this.props.initlasttime.activeStatus == '进行中' && this.props.initlasttime.worksId == null){
            return <ButtonStyle title="参与报名" clickType={this.gotakepart} />
        }
        if(this.props.initlasttime.activeStatus == '进行中' && this.props.initlasttime.worksId !== null){
            return <ButtonStyle title="个人作品" clickType={this.gotakepart}/>
        }
    }
    render() {
        return (
            <>
                {
                    this.props.initlasttime == null ? null : this.props.initlasttime.activeStatus === '未开始' ? null : <div className={Style.vote_list}>
                        <div className={Style.input_item}>
                            <InputItem placeholder="请输入参赛者编号" className={Style.input_change} value={this.state.searchVotorNum} onChange={this.changInputVotor.bind(this)} type='number'/>
                            <div className={Style.vote_search} onClick={this.searchVotor.bind(this)}>
                                <img src={SearchImg} />
                            </div>
                        </div>
                        {
                           this.initstateButton()
                        }
                        
                        {/* {
                            this.props.initlasttime.activeStatus == '进行中'? <ButtonStyle title="参与报名" clickType={this.gotakepart} />:this.props.initlasttime.worksId == null ? null: <ButtonStyle title="个人作品" clickType={this.gotakepart}/>
                        } */}
                        {/* <ButtonStyle title={this.props.initlasttime.activeStatus == '进行中'?'参与报名':
                            this.props.initlasttime.worksId == null?'活动已结束':'个人作品'
                        } clickType={this.gotakepart} /> */}
                    </div>
                }

            </>

        )
    }
}
export default withRouter(VoteList);