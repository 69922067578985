import { INIT_TURN_TABLE_LIST,TURN_LIVER,CLICK_DRAW,SHOW_DRAW_LIST,DETAIL_GIFT } from '../actionTypes'
import demo1 from '../../static/images/truntable/demo1.jpg'
//奖品列表
export function initturntablelist(state = [],action){
    switch(action.type){
        case INIT_TURN_TABLE_LIST:
        return action.data
        default:
        return state
    }
}
//验证是否是业主
export function turnliverReducer(state=[],action){
    switch(action.type){
        case TURN_LIVER:
        return action.data
        default:
        return state
    }
}

//抽中结果列表
export function drawlistReducer(state=[],action){
    switch(action.type){
        case SHOW_DRAW_LIST:
        return action.data
        default:
        return state
    }
}
//抽中礼品详情
export function detailgiftReducer(state={
    "image": demo1,//图片
        "giftName": "口罩",
        "receiveStatus": "0"//领取状态 0：未领取 1.已领取
},action){
    switch(action.type){
        case DETAIL_GIFT:
        return action.data
        default:
        return state
    }
}
