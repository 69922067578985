import React from 'react'
import Style from './vote-form.less'

import { List, InputItem, Toast, TextareaItem, ImagePicker, Button } from 'antd-mobile';
import { createForm } from 'rc-form'

import VoteBanner from '../../components/vote-banner/vote-banner'
// import connect from '../../common/connect'
import ButtonIte from '../../components/button/button'
import store from '../../store/store'
import { connect } from 'react-redux'
import { publishworkMethod,uploadfileMethod,uploadworksDemo,deletaArray } from '../../store/actions/index_actions'
import resolve from 'resolve';
var fileId = []
@connect(
    state=>({
        uploadfileReducer : state.uploadfileReducer
    }),
    dispatch=>({
        publishworkMethod: (data) => dispatch(publishworkMethod(data)),
        uploadfileMethod: (data) => dispatch(uploadfileMethod(data)),
        uploadworksDemo: (data) => dispatch(uploadworksDemo(data)),
        deletaArray:(data)=> dispatch(deletaArray(data)),
    })
)
class VoteForm extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        value: '',
        files: [],
        fileId:[],
        pics:'',
    }
    //上传图片事件
    onImageFileChange = (files, type, index,cb) => {
        if(type == 'add'){
            this.setState({
                files:  files,
            })
            this.props.uploadfileMethod(this.onUpload());
        }else if(type == 'remove'){
            this.setState({
                files:  files,
            });
            this.props.deletaArray(index)
        }
    };
    onUpload = () => {
        let formData = new FormData();
        console.log(this.state.files[this.state.files.length-1]);
        let file = this.state.files[this.state.files.length-1].file;
        formData.append('file', file);
        return formData;
    };
    handleSubmitsubmit = () => {
        console.log('2')
        this.props.form.validateFields((error,value)=>{
            if(value.name == undefined){
                this.onErrorClick('请输入主题')
                return;
            }
            if(value.name.length > 30){
                this.onErrorClick('主题不能超过30个字')
                return;
            }
            if(value.content == undefined){
                this.onErrorClick('请输入描述内容')
                return;
            }
            if(value.content.length > 200){
                this.onErrorClick('描述内容不能超过200个字')
                return;
            }
            if(this.props.uploadfileReducer.length== 0){
                this.onErrorClick('请上传作品');
                return;
            }
            value.actId = localStorage.getItem('actId');
            value.name =  value.name.replace(/\s/g, '');
            value.author =  localStorage.getItem('name');
            value.content =  value.content.replace(/\s/g, '');
            value.mobile = localStorage.getItem('phone');
            value.cusId = localStorage.getItem('cusId');
            value.pics = this.props.uploadfileReducer.join(',');
            this.props.uploadworksDemo(value)
        })
    }
    //错误信息提示
    onErrorClick = (title) => {
        Toast.info(title);
      }
    render() {
        const { files } = this.state;
        const { getFieldProps } = this.props.form;
        return (
            <div>
                <VoteBanner />
                <div className={Style.form_content} >
                    {/* <List>
                        <InputItem  
                           {...getFieldProps('author')}
                            placeholder="请输入姓名"
                            className={Style.input_style}
                        ></InputItem>
                    </List> */}
                    <List>
                    <InputItem  
                           {...getFieldProps('name')}
                           placeholder="请输入主题"
                            className={Style.input_style}
                        ></InputItem>
                       
                    </List>
                 <List  className={Style.input_style_area}>
                        <TextareaItem
                            {...getFieldProps('content')}
                            placeholder="请输入相关内容"
                        ></TextareaItem>
                    </List>
                    <ImagePicker
                        files={this.state.files}
                        onChange={this.onImageFileChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        multiple={false}
                    />
                    <p className={Style.tips}>
                        Tips: 第一张图片默认为封面效果图
                    </p>
                </div>
                <ButtonIte title="确认参加" clickType={this.handleSubmitsubmit} />
            </div>
        )
    }

}
export default createForm()(VoteForm);