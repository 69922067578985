import React from 'react'
import Style from './vote-center.less'
import { connect } from 'react-redux'
import { initlasttimeDemo } from '../../../store/actions/index_actions'
@connect(
    state => ({
        getworklistDemo: state.getworklistDemo,
        initlasttime: state.initlasttime,
    }),
    dispatch => ({
        initlasttimeDemo: (data) => dispatch(initlasttimeDemo(data))
    })
)
class VoteCenter extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        // day: '00',
        hours: '00',
        minutes: '00',
        seconds: '00'
    }
    componentDidMount() {
        this.props.initlasttimeDemo({
            actId:localStorage.getItem('actId'),
            cusId:localStorage.getItem('cusId')
        })
    }
    componentWillUnmount() {
        clearTimeout(this.time);
    }

    _countDown = () => {
        const curretTime = new Date().getTime();
        const startTime = this.props.initlasttime.startTime;
        const dTime = startTime - curretTime;
        if (dTime <= 0) {
            // 这样做更精确
            clearTimeout(this.time);
            this.setState({
                // day: '00',
                hours: '00',
                minutes: '00',
                seconds: '00'
            });
            this.props.initlasttimeDemo({
                actId:localStorage.getItem('actId'),
                cusId:localStorage.getItem('cusId')
            })
            return;
        }
        this.time = setTimeout(() => {
            this.setState(this._formatTime(dTime));
            this._countDown();
        }, 1000);
        return <div style={{ fontWeight: '700', fontSize: '30px' }}>
            {/* <span>{this.state.day}</span>: */}
                <span>{this.state.hours}</span>:
                <span>{this.state.minutes}</span>:
                <span>{this.state.seconds}</span>
        </div>;
    }
    _formatTime = (time) => {
        // const day = Math.floor(time / (1000 * 60 * 60 * 24)).toString().padStart(2, "0");
        const hours = Math.floor((time / (1000 * 60 * 60))).toString().padStart(2, "0");
        const minutes = Math.floor((time / (1000 * 60)) % 60).toString().padStart(2, "0");
        const seconds = Math.floor((time / 1000) % 60).toString().padStart(2, "0");
        return ({  hours, minutes, seconds });
    }
    getlastTime() {
        if (this.props.initlasttime !== null) {
            if (this.props.initlasttime.activeStatus === '未开始') {
                return <div className={Style.vote_center}>
                    <div className={Style.vote_center_item}>
                        <div className={Style.vote_center_item}>
                            <span style={{ color: "#ff0000", marginBottom: "8px" }}>
                                {
                                    // this.initlastsettimeout()
                                    this._countDown()
                                    // this.getlastTime()
                                }</span>
                            <span className={Style.style_name}>倒计时</span>
                        </div>
                    </div >
                </div>
            } else if (this.props.initlasttime.activeStatus === "进行中") {
                return <div className={Style.vote_center}>
                    <div className={Style.vote_center_item}>
                        <span style={{ color: "#2b5837", marginBottom: "8px" }}>{this.props.getworklistDemo.total_record}</span>
                        <span className={Style.style_name}>参与人数</span>
                    </div>
                    <div className={Style.line}></div>
                    <div className={Style.vote_center_item}>
                        <span style={{ color: "#ff0000", marginBottom: "8px" }}>进行中</span>
                    </div>
                    <div className={Style.line}></div>
                    <div className={Style.vote_center_item}>
                        <span style={{ color: "#2b5837", marginBottom: "8px" }}>{this.props.getworklistDemo.sumVotes}</span>
                        <span className={Style.style_name}>总票数</span>
                    </div>
                </div>
            }else if(this.props.initlasttime.activeStatus === "已结束"){
                return <div className={Style.vote_center}>
                <div className={Style.vote_center_item}>
                    <span style={{ color: "#2d7697", marginBottom: "8px" }}>{this.props.getworklistDemo.total_record}</span>
                    <span className={Style.style_name}>参与人数</span>
                </div>
                <div className={Style.line}></div>
                <div className={Style.vote_center_item}>
                    <span style={{ color: "#ff0000", marginBottom: "8px" }}>已结束</span>
                </div>
                <div className={Style.line}></div>
                <div className={Style.vote_center_item}>
                    <span style={{ color: "#2d7697", marginBottom: "8px" }}>{this.props.getworklistDemo.sumVotes}</span>
                    <span className={Style.style_name}>总票数</span>
                </div>
            </div>
            }
        }
    }
    render() {
        return (
            <>
                {
                    this.props.getworklistDemo == null ? null : this.getlastTime()
                }
            </>
            // <>
            //     {
            //         this.props.getworklistDemo == null ? null : <div className={Style.vote_center}>
            //             <div className={Style.vote_center_item}>
            //                 <span style={{ color: "#2d7697", marginBottom: "8px" }}>{this.props.getworklistDemo.total_record}</span>
            //                 <span className={Style.style_name}>参与人数</span>
            //             </div>
            //             <div className={Style.line}></div>
            //             <div className={Style.vote_center_item}>
            //                 <span style={{ color: "#ff0000", marginBottom: "8px" }}>{
            //                     console.log(this.props.initlasttime)
            //                     // this.getlastTime()
            //                 }</span>
            //                 <span className={Style.style_name}>倒计时</span>
            //             </div>
            //             <div className={Style.line}></div>
            //             <div className={Style.vote_center_item}>
            //                 <span style={{ color: "#2d7697", marginBottom: "8px" }}>{this.props.getworklistDemo.sumVotes}</span>
            //                 <span className={Style.style_name}>总票数</span>
            //             </div>
            //         </div>
            //     }
            // </>

        )
    }
}

export default VoteCenter;