import React from 'react'
import { withRouter} from 'react-router-dom'
import VoteBanner from '../../components/vote-banner/vote-banner'
import VoteCenter from './vote-center/vote-center.jsx'
import VoteList from './vote-list/vote-list.jsx'
import VoteCharts from './vote-charts/vote-charts'
import store from '../../store/store'
import { getworklistDemo,rankinglistDemo } from '../../store/actions/index_actions'
import { connect} from 'react-redux'
import { bindActionCreators} from 'redux'
@connect(
   state=>({
      getworklistDemo:state.getworklistDemo,
      checkrankinglist: state.checkrankinglist,
     
    }),
    // dispatch=>bindActionCreators({getworklistDemo},dispatch)
)
 class VotePage extends React.Component{
     constructor(props){
         super(props);
     }
     state = {
        paramsone:{
           current:1,
           limit:10
        },
        paramstwo:{
            current:1,
            limit:10000
         },

    }
     componentDidMount(){
        store.dispatch(getworklistDemo(this.state.paramsone));
        store.dispatch(rankinglistDemo(this.state.paramstwo))
     }
     render(){
         return(
            <div style={{width:'100%'}}>
                    <VoteBanner />
                    <div style={{padding:'15px 15px 0'}}>
                        <VoteCenter/>
                        {/* <VoteList listItem={store.getState().getworklistDemo == null?"":store.getState().getworklistDemo}/> */}
                        <VoteList/>
                    </div>
                    <VoteCharts/>
                </div>
         )
     }
 }
 export default withRouter(VotePage);
