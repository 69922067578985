import React from 'react'
//import { BrowserRouter as Router } from 'react-router-dom'
import {Route } from 'react-router-dom'
//投票活动
import VotePage from './subpage/vote-page/index.jsx'
//填写业主信心
import VoteSignUp from './subpage/vote-sign-up/vote-sign-up'
//填写投票信息
import VoteForm from './subpage/vote-form/vote-form'
//个人中心的图片预览
import VotePreview from './subpage/vote-preview/vote-preview'
//名城好司机规章
import DriverRules from './subpage/driver_rule/driver_rule'
//名城好司机提交
import DriverForm from './subpage/driver_form/driver_form'
//成功提示
import DriverToast from './subpage/driver_toast/driver_toast'
//转盘
import TurnTable from './subpage/turntable/turntable'
//转盘通知结果页
import TurnResult from './subpage/turnresult/turnresult'
//表单
import TurnForm from './subpage/turn_form/turn_form'
//发布的所有宠物,商品
// import Demo from './subpage/demo'
class RouterIndex extends React.Component {
    render() {
        return (
            <div>
                <Route path="/"  component={VotePage} exact></Route>
                <Route path="/vote-form" exact component={VoteForm}></Route>
                <Route path="/vote-preview" exact component={VotePreview}></Route>
                <Route path="/vote-sign-up" exact component={VoteSignUp}></Route>
                {/* 报名 */}
                {/* <Route path="/driver_form/:id" exact component={DriverForm}></Route> */}
                <Route path="/driver_form" exact component={DriverForm}></Route>
                <Route path="/driver_rule" exact component={DriverRules}></Route>
                <Route path="/driver_toast" exact component={DriverToast}></Route>
                <Route path="/turn_table" exact component={TurnTable}></Route>
                <Route path="/turn_result" exact component={TurnResult}></Route>
                <Route path="/turn_form" exact component={TurnForm}></Route>
                {/* <Route path="/demo" exact component={Demo}></Route> */}
            </div>
        )
    }
}
export default RouterIndex;