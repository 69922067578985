import { 
    WORK_LIST,
    WORK_DETAIL,
    UP_LOAD_WORKS,
    CHECK_RANKING_LIST,
    LAST_TIME,
    VOTE_STATE,
    VOTE_WORK,
    SIGN_INFO,
    PUBLISH_WORK,
    UP_LOAD_FILE,
    // UP_LOAD_WORKS,
 } from '../actionTypes'
//查看作品集
export function getworklistDemo(state = null,action){
    switch(action.type){
        case WORK_LIST:
        return action.data
        default:
        return state
    }
}
//查看作品详情
export function check_work_detail(state = null,action){
    switch(action.type){
        case WORK_DETAIL:
        return action.data
        default:
        return state
    }
}
//上传个人作品
export function uoloadworks (state = null,action){
    switch(action.type){
        case UP_LOAD_WORKS:
        return action.data
        default:
        return state
    }
}
//查看排行榜
export function checkrankinglist (state = null,action){
    switch(action.type){
        case CHECK_RANKING_LIST:
        return action.data
        default:
        return state
    }
}
//获取活动倒计时
export function initlasttime (state = null,action){
    switch(action.type){
        case LAST_TIME:
        return action.data
        default:
        return state
    }
}
//获取当前活动状态VOTE_STATE
export function votestate (state = null,action){
    switch(action.type){
        case VOTE_STATE:
        return action.data
        default:
        return state
    }
}
//点击投票
export function voteworkReducer (state = null,action){
    switch(action.type){
        case VOTE_WORK:
        return action.data
        default:
        return state
    }
}
// SIGN_INFO填写业主信息
export function signinfoReducer (state = null,action){
    switch(action.type){
        case SIGN_INFO:
        return action.data
        default:
        return state
    }
}
//发布摄影作品 PUBLISH_WORK
export function publishworkReducer (state = null,action){
    switch(action.type){
        case PUBLISH_WORK:
        return action.data
        default:
        return state
    }
}
//UP_LOAD_FILE
//上传图片
export function uploadfileReducer (state = null,action){
    switch(action.type){
        case UP_LOAD_FILE:
        return action.data
        default:
        return state
    }
}
