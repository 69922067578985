import React from 'react'
import Style from './works-exhibition.less'
import { voteworkMethod, getworklistDemo } from '../../../../store/actions/index_actions'
import { Pagination, Icon } from 'antd-mobile';
import store from '../../../../store/store'
import { connect } from 'react-redux'
import ChartExhibition from '../../../../components/chart-exhibition/chart-exhibition'
import { promises } from 'fs-extra'
import resolve from 'resolve'
import nowork from '../../../../static/images/vote-page/nowork.png'
@connect(
   state => ({
      getworklist: state.getworklistDemo,
   }),
   dispatch => ({
      voteworkMethod: (params, data) => dispatch(voteworkMethod(params, data)),
      // getworklistDemo: (data) => dispatch(getworklistDemo(data))
   })
)

class WorksExhibition extends React.Component {
   constructor(props) {
      super(props)
   }
   state={
      currentpage:1,
      limit:10000,
   }
   render() {
      return (
         <div className={Style.chart_exhibition}>
            {
               store.getState().getworklistDemo == null ? '' : store.getState().getworklistDemo.data.length == 0 ? <img src={nowork} style={{ width: '198px', height: '208px', margin: '30px auto', display: 'block' }} /> : <>
                  {store.getState().getworklistDemo.data.map((item,index) => {
                     return (<><ChartExhibition key={index} item={item} voteClick={this.votemethod.bind(this, item)} />  </>)
                  })}
               </>
            }
         </div>
      )
   }
   votemethod(item) {
      var data = {
         actId: localStorage.getItem('actId'),
         worksId: item.id
      }
      this.props.voteworkMethod(localStorage.getItem('cusId'), data)
      setTimeout(() => {
         this.props.getworklistDemo({
            current: this.state.currentpage,
            limit: this.state.limit,
         })
      }, 1000);

   }

}
//  点击投票
export default WorksExhibition;