import React from 'react'
import Style from './chart-list.less'
import OneImage from '../../static/images/vote-page/one.png'
import TwoImage from '../../static/images/vote-page/two.png'
import ThreeImage from '../../static/images/vote-page/three.png'
class ChartList extends React.Component {
    constructor(props) {
        super(props);
    }
    initrenderImage(){
       // console.log(this.props.num)
        if(this.props.num === 1){
            return <img src={OneImage} className={Style.brand_image}/>
        }else if(this.props.num === 2){
            return <img src={TwoImage} className={Style.brand_image}/>
        }else if(this.props.num === 3){
            return <img src={ThreeImage} className={Style.brand_image}/>
        }else{
            return <span>{this.props.num}</span>
        }
    }
    render() {
        return (
            <div className={Style.menu_chart_list}>
                <div className={Style.menu_chart_left} style={{ fontSize: '36px', fontWeight: 'bold' }}>{this.initrenderImage()}</div>
                <div className={Style.menu_chart_left}>{this.props.item.id}</div>
                <div className={`${Style.menu_chart_right} ${Style.two}`} onClick={this.props.onviewworks}>
                    《{this.props.item.name}》
            </div>
                <div className={Style.menu_chart_right}>{this.props.item.votesNum == null ? '0': this.props.item.votesNum}</div>
            </div>
        )
    }
}
export default ChartList;