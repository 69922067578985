import { INIT_HAVE_TIME,DRIVE_SIGN_UP} from '../actionTypes'
import axios from 'axios'
import '../../common/axios'
import { Toast } from 'antd-mobile'
import history from '../history'
// import '../../common/sdk'
// import { inithavetime } from '../reducers/drive_reducers'
//查看作品集
const inithavetime = (data) =>({
    type: INIT_HAVE_TIME,
    data:data
})
let inithavetimeMethod = (params) => {
    return (dispatch, getState) => {
        axios({
            url: "/ctds/driverInfos/wx/getActivity/"+localStorage.getItem('actId')+"/"+localStorage.getItem('id'),
            method: 'GET',
        }).then(res => {
            if(res.data.ret == 0){
                const data = res.data.retData;
                const action = inithavetime(data);
                dispatch(action);  //redux-thunk支持dispatch作为参数
            }
        }).catch(err => {
            console.log(err)
        })
    }
}
//去报名
const drivesignup = (data) =>({
    type: DRIVE_SIGN_UP,
    data:data
})
let drivesignupMethod = (params) => {
    return (dispatch, getState) => {
        axios({
            url: "/ctds/driverInfos/wx/save",
            method: 'POST',
            data:params
        }).then(res => {
            if(res.data.ret == 0){
                const data = res.data.retData;
                const action = drivesignup(data);
                dispatch(action);  //redux-thunk支持dispatch作为参数
                Toast.info("报名成功",1);
                setTimeout(()=>{
                    history.replace('/driver_toast')
                    // window.wx.miniProgram.switchTab({
                    //     url:'/pages/index/index'
                    // })
                },1000) 
            }
        }).catch(err => {
            console.log(err)
        })
    }
}
export { inithavetimeMethod,drivesignupMethod };