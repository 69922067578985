import React from 'react'
import Style from './works-chart.less'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ChartList from '../../../../components/chart-list/chart-list'
import novote from '../../../../static/images/vote-page/novote.png'
@connect(
    state => ({
        checkrankinglist: state.checkrankinglist
    })
)
class WorkChart extends React.Component {
    godetail(item) {
        console.log(item);
        this.props.history.push({
            pathname: '/vote-preview',
            state: {
                workid: item.id
            }
        })
    }
    render() {
        const { checkrankinglist } = this.props;
        return (
            <div className={Style.menu_chart}>
                {
                    checkrankinglist == null ? null : checkrankinglist.data.length == 0 ? <img src={novote} style={{ width: '198px', height: '208px', margin: '30px auto', display: 'block' }} /> : <>
                        <div className={Style.menu_title}>
                            <div className={Style.menu_span_left}>排名</div>
                            <div className={Style.menu_span_left}>编号</div>
                            <div className={Style.menu_span} >作品名称</div>
                            <div className={Style.menu_span} >总票数</div>
                        </div>
                        <div className={Style.menu_chart_content}>
                            {
                                checkrankinglist == null ? null : checkrankinglist.data.map((item, index) => {
                                    return <ChartList key={index} item={item} num={index + 1} onviewworks={this.godetail.bind(this, item)} />
                                })
                            }

                        </div>
                    </>
                }

            </div>
        )
    }
}

export default withRouter(WorkChart);